import type { GetStaticPropsContext, InferGetStaticPropsType, NextPage } from 'next';
import { ProductsContainer } from '@/containers/ProductsContainer/ProductsContainer';
import { getStaticProps as getProductsStaticProps } from '@/containers/ProductsContainer/getProductsStaticProps';
import { ConsumerTypesEnum } from '@/types/consumerTypes';

const ProductsIndexRoute: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  typesenseServerState,
  productVariantIds,
}) => {
  return (
    <ProductsContainer
      productVariantIds={productVariantIds || []}
      typesenseServerState={typesenseServerState}
    />
  );
};

export async function getStaticProps(context: GetStaticPropsContext) {
  return getProductsStaticProps(context, { consumerType: ConsumerTypesEnum.PERSONAL });
}

export default ProductsIndexRoute;
